exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-certificates-labels-js": () => import("./../../../src/pages/certificates-labels.js" /* webpackChunkName: "component---src-pages-certificates-labels-js" */),
  "component---src-pages-for-your-home-js": () => import("./../../../src/pages/for-your-home.js" /* webpackChunkName: "component---src-pages-for-your-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-templates-blog-post-template-js": () => import("./../../../src/pages/templates/blogPostTemplate.js" /* webpackChunkName: "component---src-pages-templates-blog-post-template-js" */),
  "component---src-pages-terms-conditions-consumers-js": () => import("./../../../src/pages/terms-conditions-consumers.js" /* webpackChunkName: "component---src-pages-terms-conditions-consumers-js" */),
  "component---src-pages-terms-conditions-dte-js": () => import("./../../../src/pages/terms-conditions-dte.js" /* webpackChunkName: "component---src-pages-terms-conditions-dte-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-general-js": () => import("./../../../src/pages/thank-you-general.js" /* webpackChunkName: "component---src-pages-thank-you-general-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-missing-act-js": () => import("./../../../src/pages/thank-you-missing-act.js" /* webpackChunkName: "component---src-pages-thank-you-missing-act-js" */)
}

